@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap');

.wrapChessHeader {
    height: 84px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    box-shadow: 0px 5px 15px 0px gray;
    position: fixed;
    z-index: 10;
    @media(max-width: 520px){
        height: 64px;
    }
    .wrapHome {
        display: flex;
        height: 32px;
        width: 25%;
        margin-left: 20px;
        cursor: pointer;
        align-items: end;
        text-decoration: none;
        .homeImage {
            width: 32px;
            height: 32px;
            background-size: cover;
            background-image: url("./images/home.png");
            background-position: center center;
        }  
        > span {
            color:#3d4d60;
            font-size: 22px;
            font-weight: 600;
            margin-left: 8px;
        }
    }
    .wrapPhone {
        width: 30%;
        margin-right: 20px;
        display: flex;
        justify-content: end;
        cursor: pointer;
        .phone {
            display: flex;
            color:#3d4d60;
            font-size: 22px;
            font-weight: 600;
        }
        .phoneImage {
            width: 32px;
            height: 32px;
            background-size: cover;
            background-image: url("./images/phone.png");
            background-position: center center;
            margin-right: 10px;
        }
    }
    .chessBtn {
        display: flex;
        width: 45%;
        justify-content: space-around;
        .wrapBtn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 188px;
            min-width: 188px;
            height: 52px;
            border-radius: 12px;
            box-shadow: 0px 5px 8px 0px gray;
            margin: 8px;
            cursor: pointer;
            &:active {
                transform: translateY(4px);
            }
            // &:hover {
            //     box-shadow: 0px 4px 8px 0px rgba(222, 58, 17, 1);
            // }
            > span {
                color: gray;
                font-size: 20px;
                font-weight: 700;

            }
        }
    }
    .selectPage {
        border: none;
        outline: none;
        font-weight: 600;
        font-size: 20px;
        min-width: 40%;
        margin: 8px;
        font-family: 'Montserrat', sans-serif;
        border-bottom: 1px solid rgb(71,74,81);
        background-color: white;
    }
}
.wrapStatus {
    width: 100%;
    display: flex;
    justify-content: start;
    padding-left: 20px;
    position: fixed;
    margin-top: 104px;
    @media(max-width: 520px){
        height: 32px;
        margin-top: 88px;
    }
    .status {
        //width: 100%;
        height: 38px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 8px;
        @media(max-width: 520px){
            height: 32px;
        }
        > span {
            padding-left: 10px;
            padding-right: 10px;
            color: white;
            font-weight: 600;
            font-size: 16px;
            @media(max-width: 520px){
                font-size: 13px;
                padding-left: 5px;
                padding-right: 5px;
            }
        }

    }
}
