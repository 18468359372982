.mainLayouts {
    width: 94%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 110px;
    margin-left: auto;
    margin-right: auto;
    @media(max-width: 520px){
        margin-top: 90px;
    }
    .title {
        font-size: 44px;
        color: #3d4d60;
        margin-bottom: 20px;
        font-family: "Rubik", sans-serif;
        font-weight: 900;
        @media(max-width: 520px){
            font-size: 24px;
            margin-bottom: 8px;
        }
    }
    .wrapLayouts {
        display: flex;
        justify-content: space-between;
        width: 100%;
        @media(max-width: 520px){
            flex-direction: column;
        }
        .layouts {
            display: flex;
            flex-direction: column;
            width: 33%;
            padding: 26px;
            position: relative;
            @media(max-width: 520px){
                width: 100%;
            }
            .search {
                position: absolute;
                box-shadow: 0px 5px 8px 0px gray;
                border-radius: 12px;
                cursor: pointer;
                &:hover {
                    box-shadow: 0px 5px 8px 0px rgba(222, 58, 17, 1);
                }
                &:active {
                    transform: translateY(4px);
                }
            }
            .wrapImg {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                border-radius: 12px;
                box-shadow: 0px 5px 14px 1px gray;
                overflow: hidden;
                cursor: pointer;
                &:hover {
                    box-shadow: 0px 5px 14px 1px rgba(222, 58, 17, 1);
                }
                &:active {
                    box-shadow: 0px 5px 14px 1px rgba(222, 58, 17, 1);
                    animation: scaling 1s;
                }
            }
            > span {
                font-size: 20px;
                font-weight: 700;
                font-family: 'Montserrat', sans-serif;
                text-align: center;
                margin-top: 14px;
            }
            
        }
    }
}