@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap');

.mainChess {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    overflow: hidden;
    position: relative;
    .wrapChess {
        width: 100%;
        min-width: 1100px;
        display: flex;
        flex-direction: column;
        margin-top: 118px;
        overflow: hidden;
        @media(max-width: 780px){
            min-width: 100%; 
        }
        .title {
            font-size: 44px;
            color: #3d4d60;
            margin-bottom: 20px;
            margin-left: auto;
            margin-right: auto;
            font-family: "Rubik", sans-serif;
            font-weight: 900;
            @media(max-width: 520px){
                font-size: 24px;
                margin-bottom: 8px;
            }
        }
        .chessBlock {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            padding-left: 60px;
            padding-right: 80px;
            @media(max-width: 780px){
                padding-left: 10px;
                padding-right: 10px;
            }
            .chess {
                width: 22%;
                min-width: 230px;
                height: 120px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                border-radius: 18px;
                background-color: grey;
                margin-top: 10px;
                margin-bottom: 8px;
                padding-left: 20px; 
                cursor: pointer;
                box-shadow: 0px 5px 14px 1px gray;
                &:hover {
                    box-shadow: 0px 5px 14px 0px #3d4d60;
                }
                @media(max-width: 780px){
                    min-width: 22%; 
                    height: 80px;
                    padding-left: 8px;
                    border-radius: 8px;
                }
                &:active {
                    transform: translateY(4px);
                }
                .wrapText {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    padding-right: 20px;
                    @media(max-width: 520px){
                        flex-direction: column;
                        padding-right: 6px;
                    }
                }
            }
            .text {
                color: white;
                font-size: 16px;
                font-weight: 600;
                @media(max-width: 520px){
                    font-size: 11px;
                }
            }
        }
        .numFloor {
            font-size: 20px;
            font-weight: 700;
            color: #403A3A;
            @media(max-width: 520px){
                font-size: 16px;
            }
        }
    }

    .wrapShowCard {
        position: fixed;
        top: 84px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-bottom: 120px;
        width: 430px;
        height: 100%;
        background-color: white;
        transition: all 1s ease;
        z-index: 10;
        overflow-y: scroll;
        overflow-x: hidden;
        @media(max-width: 520px){
            width: 100%; 
        }
    }
    .wrapTitle {
        display: flex;
        width: 100%;
        align-items: flex-end;
        justify-content: space-between;
    }
    .title {
        margin-top: 14px;
        padding-left: 30px;
        font-size: 20px;
        font-weight: 700;
        color: rgb(71,74,81);
    }
    .textCard {
        margin-top: 10px;
        padding-left: 30px;
        font-size: 18px;
        font-weight: 600;
        color: rgb(71,74,81);
    }
    .wrapStatus {
        width: 162px;
        min-height: 40px;
        border-radius: 12px;
        margin-left: 30px;
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: 500;
        color: white;
        .search {
            position: absolute;
            box-shadow: 0px 5px 8px 0px gray;
            border-radius: 12px;
            cursor: pointer;
            &:hover {
                box-shadow: 0px 5px 8px 0px rgba(222, 58, 17, 1);
            }
            &:active {
                transform: translateY(4px);
            }
        }
    }
    .wrapSubmitBtn {
        width: 85%;
        min-height: 52px;
        margin-top: 20px;
        margin-bottom: 5px;
        border-radius: 12px;
        background-color: darkgreen;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: 500;
        color: white;
        margin-left: auto;
        margin-right: auto;
        cursor: pointer;
        &:active {
            transform: translateY(4px);
        }
    }
    .wrapCharacter {
        display: flex;
        width: 100%;
        margin-left: 30px;
        align-items: flex-end;
        margin-bottom: 18px;
    }
    ::-webkit-scrollbar {
        width: 10px;
    } 
    ::-webkit-scrollbar-track {
        background: white;
    }
    ::-webkit-scrollbar-thumb {
        border: 3px solid #eee;
        border-radius: 15px;
        background-color: gray;
    }

    .wrapBlocks {
        display: flex;
        height: 100%;
        width: 460px;
        flex-direction: column;
        background-color: white;
        border-radius: 12px;
        padding-left: 38px;
        padding-right: 38px;
        padding-top: 14px;
        padding-bottom: 26px;
        z-index: 12;
        @media(max-width: 520px){
            width: 90%; 
            height: 88vh;
            justify-content: space-around;
            padding-left: 12px;
            padding-right: 6px;
            overflow-y: scroll;
            overflow-x: hidden;
        }
        .titleModal {
            margin-top: 10px;
            font-size: 21px;
            font-weight: 700;
            color: rgb(71,74,81);
            font-family: 'Montserrat', sans-serif;
            padding-bottom: 4px;
        }
        .text {
            //margin-top: 10px;
            font-size: 16px;
            font-weight: 600;
            color: rgb(71,74,81);
            font-family: 'Montserrat', sans-serif;
        }
        .input {
            border: none;
            outline: none;
            font-weight: 600;
            font-size: 18px;
            width: 100%;
            height: 40px;
            font-family: 'Montserrat', sans-serif;
            border-bottom: 2px solid rgb(71,74,81);
            background-color: white;
            color: #000;
        }
        .check {
            font-weight: 600;
            font-size: 14px;
            font-family: 'Montserrat', sans-serif;
            margin-left: 10px;
        }
        .btn {
            width: 100%;
            height: 48px;
            border-radius: 8px;
            background-color: darkgreen;
            font-weight: 600;
            font-size: 20px;
            font-family: 'Montserrat', sans-serif;
            color: white;
        }
    }
    .wrapPolicy {
        width: 600px;
        height: 88vh;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        background-color: white;
        border-radius: 8px;
        overflow-y: scroll;
        overflow-x: hidden;
        @media(max-width: 520px){
            width: 90%;
        }
    }
    .wrapModalSuccess {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        width: 340px;
        height: 230px;
        border: 4px solid white;
        border-radius: 18px;
        text-align: center;
        > span {
            font-weight: 700;
            font-size: 28px;
            color: white;
        }
    }
}
