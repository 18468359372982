@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap');

.wrapPolicy {
    width: 90%;
    margin-top: 20px;
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    font-size: 18px;
    font-weight: 600;
    color: rgb(71,74,81);
    font-family: 'Montserrat', sans-serif;
}