html {
    scroll-behavior: smooth;
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 80px;
    background-color: rgba(42, 56, 56, 0.4);
    position: fixed;
    z-index: 3;
    .wrapLang {
        height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        @media(max-width: 520px){
            margin-left: 0px;
        }
        .showLang {
            display: flex;
            justify-content: space-around;
            align-items: center;
            height: 40px;
            width: 130px;
            background-color: white;
            border-radius: 8px;
            position: absolute;
            left: 204px;
            top: 68px;
            padding-top: 8px;
            padding-bottom: 8px;
            @media(max-width: 520px){
                left: 26%;
            }
            > span {
                font-size: 22px;
                font-weight: 800;
                color: #3d4d60;
            }
        }
    }
    .menu {
        width: 46px;
        height: 46px;
        background-size: cover;
        background-image: url("./images/menu.png");
        background-position: center center;
        text-align: center;
        margin-left: 20px;
        margin-bottom: 10px;
        margin-top: 10px;
        z-index: 3
    }
    .close {
        width: 46px;
        height: 46px;
        background-size: cover;
        background-image: url("./images/close.png");
        background-position: center center;
        text-align: center;
        margin-left: 20px;
        margin-bottom: 10px;
        margin-top: 10px;
        z-index: 3
    }
    .wrapLinks {
        width: 58%;
        display: flex;
        justify-content: space-around;
        @media(max-width: 520px){
            position: absolute;
            top: 68px;
            flex-direction: column;
            align-items: start;
            background-color: white;
            border-radius: 12px;
            width: 64%;
        }
        .links {
            color: white;
            font-weight: 700;
            line-height: 30px;
            cursor: pointer;
            &:hover {
               color: rgb(86,3,25);
            }
            @media(max-width: 520px){
                margin-left: 20px;
                color: rgb(42, 56, 56);
            }
        }
    }
    .phoneImage {
        width: 38px;
        height: 38px;
        cursor: pointer;
        margin-right: 30px;
        margin-top: 4px;
        background-size: cover;
        background-image: url("./images/phone.png");
        background-position: center center;
        @media(max-width: 520px){
            margin-right: 15px;
        }
    }
    .wrapMessenger {
        height: 100%;
        margin-right: 30px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
}