.mainWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: start;
    align-items: center;
    overflow: hidden;
    .wpapMainTitle {
        background-color: rgba(41,49,51, 0.9);
        width: 100%;
        height: 100vh;
        z-index: 2;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        .centered {
            width: 100%;
            height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;
            > span {
                font-size: 39px;
                color: white;
                font-family: "Rubik", sans-serif;
                font-weight: 900;   
                line-height: 62px;
                text-align: center;
                @media(max-width: 520px){
                    font-size: 19px;
                    font-weight: 800;
                    line-height: 42px;
                }
            }
        }
    
    }
    .title {
        font-size: 44px;
        color: #3d4d60;
        margin-bottom: 20px;
        font-family: "Rubik", sans-serif;
        font-weight: 900;
        text-align: center;
        @media(max-width: 520px){
            font-size: 22px;
            margin-bottom: 20px;
        }
    }
    .text {
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        @media(max-width: 520px){
            font-size: 16px;
            margin-top: 0px;
        }
    }
    .mainImg {
        position: absolute;
        z-index: 2;
        width: 100%;
        height: auto;
        background-position: center center;
        background-size: cover;
        @media(max-width: 520px){
            height: 100%;
        }
    }
    .carouselImgs {
        width: 100%;
        height: auto;
        background-position: center center;
        background-size: cover;
        @media(max-width: 520px){
            height: 100%;
        }
    }
    .wrapAdvantages {
        display: flex;
        width: 84%;
        flex-direction: column;
        margin-top: 50px;
        @media(max-width: 520px){
            width: 92%;
            margin-top: 35px;
        }
        .advantages {
            display: flex;
            width: 100%;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 52px;
            @media(max-width: 520px){
                flex-direction: column;
                margin-top: 0px;
            }
            .titleAdvant {
                font-size: 22px;
                font-family: "Rubik", sans-serif;
                font-weight: 700;
                color: white;
                margin-top: 28px;
                text-align: center;
            }
            .textAdvant {
                padding-top: 28px;
                padding-bottom: 60px;
                color: white;
                font-size: 16px;
                line-height: 24px;
            }
            .blockMain {
                width: 31%;
                height: 100%;
                min-width: 280px;
                background-color: #647587;
                border-radius: 8px;
                margin: 8px;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-left: 32px;
                padding-right: 20px;
                @media(max-width: 520px){
                    width: 100%;
                    margin: 0px;
                    margin-top: 50px;
                }

            }
            .block {
                width: 31%;
                height: 100%;
                min-width: 280px;
                background-color: #647587;
                border-radius: 8px;
                margin: 8px;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-left: 32px;
                padding-right: 20px;
                margin-top: 100px;
                @media(max-width: 520px){
                    width: 100%;
                    margin: 0px;
                    margin-top: 50px;
                }

            }
            .flat1Image {
                margin-left: auto;
                margin-right: auto;
                margin-top: -38px;
                border-radius: 6px;
                width: 152px;
                height: 110px;
                background-size: cover;
                background-image: url("./images/flat1.webp");
                background-position: center center;
            }
            .homeImage {
                margin-left: auto;
                margin-right: auto;
                margin-top: -38px;
                border-radius: 6px;
                width: 152px;
                height: 110px;
                background-size: cover;
                background-image: url("./images/home.webp");
                background-position: center center;
            }
            .lotusImage {
                margin-left: auto;
                margin-right: auto;
                margin-top: -38px;
                border-radius: 6px;
                width: 152px;
                height: 110px;
                background-size: cover;
                background-image: url("./images/lotus.webp");
                background-position: center center;
            }
        }
    }
    .wrapPeculiarities {
        display: flex;
        width: 84%;
        flex-direction: column;
        margin-top: 80px;
        @media(max-width: 520px){
            margin-top: 48px;
        }
    }
    .wrapMap {
        display: flex;
        width: 84%;
        flex-direction: column;
        margin-top: 50px;
        .map {
            height: 550px;
            @media(max-width: 520px){
                height: 350px;
            }
        }
        @media(max-width: 520px){
            width: 88%;
            margin-top: 20px;
        }
    }
    .wrapScheme {
        display: flex;
        flex-direction: column;
        width: 84%;
        height: 100%;
        margin-top: 80px;
        @media(max-width: 520px){
            width: 88%;
            margin-top: 48px;
        }
        .wrapBlock {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-right: 20px;
        }
    }
    .wrapPaymenTerms {
        display: flex;
        width: 84%;
        flex-direction: column;
        margin-top: 80px;
        @media(max-width: 520px){
            width: 88%;
            margin-top: 45px;
        }
        .wrapModule {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 30px;
            @media(max-width: 520px){
                flex-direction: column;
                margin-top: 10px;
            }
            .module {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 30%;
                height: 90px;
                border: solid 3px #647587;
                border-radius: 12px;
                position: relative;
                cursor: pointer;
                @media(max-width: 520px){
                    width: 100%;
                    margin-bottom: 20px;
                }
            }
            .titleModule {
                padding-top: 30px;
                font-size: 24px;
                font-weight: 700;
                color: #647587;
            }
            .textModule {
                padding-left: 20px;
                padding-right: 20px;
                font-size: 18px;
                line-height: 30px;
                padding-top: 20px;
                padding-bottom: 20px;
            }
            .showModule {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 30%;
                height: 100%;
                border: solid 3px #647587;
                border-radius: 12px;
                position: relative;
                cursor: pointer;
                @media(max-width: 520px){
                    width: 100%;
                    margin-bottom: 20px;
                }
            }
            .arrow {
                position: absolute;
                right: 0;
                width: 50px;
                height: 50px;
                bottom: 14px;
            }
        }
    }
    .wrapNews {
        display: flex;
        width: 84%;
        flex-direction: column;
        margin-top: 80px;
        @media(max-width: 520px){
            flex-direction: column;
            margin-top: 10px;
        }
    }
    .wrapChooseFlat {
        position: fixed;
        right: 10px;
        bottom: 50px;
        z-index: 2;
        width: 138px;
        height: 138px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 80px;
        background-color: #3d4d60;
        padding-bottom: 10px;
        cursor: pointer;
        box-shadow: 2px 2px 15px 4px darkslategrey;
        text-decoration: none;
        text-align: center;
        @media(max-width: 520px){
            width: 108px;
            height: 108px;
            bottom: 40px;
        }
        &:active {
            transform: translateY(4px);
        }
        &:hover {
            background-color: green;
        }
        .flatImage {
            width: 60px;
            height: 60px;
            background-size: cover;
            background-image: url("./images/flat.png");
            background-position: center center;
            @media(max-width: 520px){
                width: 48px;
                height: 48px;
            }
        }
        > span {
            font-size: 17px;
            font-weight: 600;
            color: white;
            @media(max-width: 520px){
               font-size: 14px;
            }
        }
    }
    .wrapCreatedPF {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }

}
