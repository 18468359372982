.wrapInteractive {
    display: flex;
    width: 100%;
    height: auto;
    margin-top: 60px;
    position: relative;
    z-index: 1;
    // @media(max-width: 520px){
    //     width: auto;
    //     max-height: 100vh;
    //     overflow-x: scroll;
    // }
    .image {
        width: 100%;
        height: auto;
        // @media(max-width: 520px){
        //     width: auto;
        //     max-height: 100vh;
        // }
    }
    .home1Content {
        position: absolute;
        top: 16%;
        right: 8%;
        width: 258px;
        height: 160px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: white;
        border-radius: 12px;
        padding: 16px;
        .title {
            text-align: center;
            font-weight: 700;
            font-size: 20px;
            color: #3d4d60;
        }
        .text {
            font-weight: 600;
            font-size: 16px;
            color: #3d4d60;
        }
    }
    %stylesIMG {
        position: absolute;
        right: 24.5%; 
        width: 15%;
        height: 2.9%;
        background-size: cover; 
        background-position: center center;
    }
    %hoverIMG {
        width: 14.5%;
        height: 5%;
        right: 25%; 
    }
    .floor18 {
        @extend %stylesIMG;
        top: 44.5%;
        @media(max-width: 520px){
            width: 58%;
            right: 16%;
        }
        &:hover {
            @extend %hoverIMG;
            top: 43.5%;
            background-image: url(./images/18.png);
            @media(max-width: 520px){
                width: 58%;
                right: 16%;
            }
        }
    }
    .floor17 {
        @extend %stylesIMG;
        top: 46.5%;
        @media(max-width: 520px){
            width: 58%;
            right: 16%;
        }
        &:hover {
            @extend %hoverIMG;
            top: 46.5%;
            background-image: url(./images/17.png);
            @media(max-width: 520px){
                width: 58%;
                right: 16%;
            }
        }
    }
    .floor16 {
        @extend %stylesIMG;
        top: 49%;
        @media(max-width: 520px){
            width: 58%;
            right: 16%;
        }
        &:hover {
            @extend %hoverIMG;
            top: 49.5%;
            background-image: url(./images/16.png);
            @media(max-width: 520px){
                width: 58%;
                right: 16%;
            }
        }
    }
    .floor15 {
        @extend %stylesIMG;
        top: 52%;
        @media(max-width: 520px){
            width: 58%;
            right: 16%;
        }
        &:hover {
            @extend %hoverIMG;
            top: 51.5%;
            background-image: url(./images/15.png);
            @media(max-width: 520px){
                width: 58%;
                right: 16%;
            }
        }
    }
    .floor14 {
        @extend %stylesIMG;
        top: 54%;
        @media(max-width: 520px){
            width: 58%;
            right: 16%;
        }
        &:hover {
            @extend %hoverIMG;
            top: 53.5%;
            background-image: url(./images/14.png);
            @media(max-width: 520px){
                width: 58%;
                right: 16%;
            }
        }
    }
    .floor13 {
        @extend %stylesIMG;
        top: 57%;
        @media(max-width: 520px){
            width: 58%;
            right: 16%;
        }
        &:hover {
            @extend %hoverIMG;
            top: 57%;
            background-image: url(./images/13.png);
            @media(max-width: 520px){
                width: 58%;
                right: 16%;
            }
        }
    }
    .floor12 {
        @extend %stylesIMG;
        top: 59.6%;
        @media(max-width: 520px){
            width: 58%;
            right: 16%;
        }
        &:hover {
            @extend %hoverIMG;
            top: 59.5%;
            background-image: url(./images/12.png);
            @media(max-width: 520px){
                width: 58%;
                right: 16%;
            }
        }
    }
    .floor11 {
        @extend %stylesIMG;
        top: 62.3%;
        @media(max-width: 520px){
            width: 58%;
            right: 16%;
        }
        &:hover {
            @extend %hoverIMG;
            top: 61.5%;
            background-image: url(./images/11.png);
            @media(max-width: 520px){
                width: 58%;
                right: 16%;
            }
        }
    }
    .floor10 {
        @extend %stylesIMG;
        top: 64.5%;
        @media(max-width: 520px){
            width: 58%;
            right: 16%;
        }
        &:hover {
            @extend %hoverIMG;
            top: 65%;
            height: 4%;
            background-image: url(./images/10.png);
            @media(max-width: 520px){
                width: 58%;
                right: 16%;
            }
        }
    }
    .floor9 {
        @extend %stylesIMG;
        top: 67.5%;
        @media(max-width: 520px){
            width: 58%;
            right: 16%;
        }
        &:hover {
            @extend %hoverIMG;
            top: 67%;
            height: 4.6%;
            background-image: url(./images/9.png);
            @media(max-width: 520px){
                width: 58%;
                right: 16%;
            }
        }
    }
    .floor8 {
        @extend %stylesIMG;
        top: 70%;
        @media(max-width: 520px){
            width: 58%;
            right: 16%;
        }
        &:hover {
            @extend %hoverIMG;
            top: 69%;
            height: 4.4%;
            background-image: url(./images/8.png);
            @media(max-width: 520px){
                width: 58%;
                right: 16%;
            }
        }
    }
    .floor7 {
        @extend %stylesIMG;
        top: 72.7%;
        @media(max-width: 520px){
            width: 58%;
            right: 16%;
        }
        &:hover {
            @extend %hoverIMG;
            top: 72.5%;
            height: 4.4%;
            background-image: url(./images/7.png);
            @media(max-width: 520px){
                width: 58%;
                right: 16%;
            }
        }
    }
    .floor6 {
        @extend %stylesIMG;
        top: 75.2%;
        @media(max-width: 520px){
            width: 58%;
            right: 16%;
        }
        &:hover {
            @extend %hoverIMG;
            top: 75%;
            height: 4.1%;
            width: 14.55%;
            background-image: url(./images/6.png);
            @media(max-width: 520px){
                width: 58%;
                right: 16%;
            }
        }
    }
    .floor5 {
        @extend %stylesIMG;
        top: 78%;
        @media(max-width: 520px){
            width: 58%;
            right: 16%;
        }
        &:hover {
            @extend %hoverIMG;
            top: 78%;
            height: 3.5%;
            background-image: url(./images/5.png);
            @media(max-width: 520px){
                width: 58%;
                right: 16%;
            }
        }
    }
    .floor4 {
        @extend %stylesIMG;
        top: 80.5%;
        @media(max-width: 520px){
            width: 58%;
            right: 16%;
        }
        &:hover {
            @extend %hoverIMG;
            top: 80.5%;
            height: 3.1%;
            width: 14.58%;
            background-image: url(./images/4.png);
            @media(max-width: 520px){
                width: 58%;
                right: 16%;
            }
        }
    }
    .floor3 {
        @extend %stylesIMG;
        top: 83%;
        @media(max-width: 520px){
            width: 58%;
            right: 16%;
        }
        &:hover {
            @extend %hoverIMG;
            top: 83.5%;
            height: 2.6%;
            width: 14.58%;
            right: 24.9%;
            background-image: url(./images/3.png);
            @media(max-width: 520px){
                width: 58%;
                right: 16%;
            }
        }
    }
    .floor2 {
        @extend %stylesIMG;
        top: 85.5%;
        @media(max-width: 520px){
            width: 58%;
            right: 16%;
        }
        &:hover {
            @extend %hoverIMG;
            top: 85.5%;
            height: 2.6%;
            width: 14.58%;
            right: 24.9%;
            background-image: url(./images/2.png);
            @media(max-width: 520px){
                width: 58%;
                right: 16%;
            }
        }
    }
    .floor1 {
        @extend %stylesIMG;
        top: 88%;
        @media(max-width: 520px){
            width: 58%;
            right: 16%;
        }
        &:hover {
            @extend %hoverIMG;
            top: 88%;
            height: 3.6%;
            width: 14.78%;
            right: 24.83%;
            background-image: url(./images/1.png);
            @media(max-width: 520px){
                width: 58%;
                right: 16%;
            }
        }
    }

    %hoverPlane {
        background-size: cover; 
        background-position: center center;
    }
    .typeA {
        position: absolute;
        left: 14.8%; 
        top: 19.5%;
        width: 36%;
        height: 31.5%;
        z-index: 2;
        &:hover {
            @extend %hoverPlane;
            background-image: url(./images/a.png);
        }
    }
    .typeB {
        position: absolute;
        right: 14.5%; 
        top: 19.5%;
        width: 36.2%;
        height: 31.3%;
        z-index: 2;
        &:hover {
            @extend %hoverPlane;
            background-image: url(./images/b.png);
        }
    }
    .typeC {
        position: absolute;
        left: 5%; 
        bottom: 19.5%;
        width: 34%;
        height: 35%;
        &:hover {
            @extend %hoverPlane;
            left: 4.5%; 
            top: 19.5%;
            width: 33.6%;
            height: 60.3%;
            background-image: url(./images/c.png);
            z-index: 1;
        }
    }
    .typeD {
        position: absolute;
        right: 5%; 
        bottom: 19.5%;
        width: 34%;
        height: 35%;
        &:hover {
            @extend %hoverPlane;
            right: 4.5%; 
            top: 19.5%;
            width: 33.6%;
            height: 60.3%;
            background-image: url(./images/d.png);
            z-index: 1;
        }
    }
    .typeE {
        position: absolute;
        left: 4.7%; 
        top: 19%;
        width: 21.5%;
        height: 28.5%;
        &:hover {
            @extend %hoverPlane;
            background-image: url(./images/e.png);
        }
    }
    .typeF {
        position: absolute;
        left: 4.5%; 
        bottom: 20%;
        width: 21.7%;
        height: 34.5%;
        &:hover {
            @extend %hoverPlane;
            background-image: url(./images/f.png);
        }
    }
    .typeG {
        position: absolute;
        right: 4.8%; 
        top: 18.8%;
        width: 37.2%;
        height: 61.2%;
        &:hover {
            @extend %hoverPlane;
            background-image: url(./images/g.png);
        }
    }

    .textModal {
        position: absolute;
        bottom: 30px;
        left: 35%;
        font-weight: 700;
        font-size: 20px;
        text-align: center;
        color: #3d4d60;
        @media(max-width: 520px){
            left: 25%;
            bottom: 16px;
            font-size: 16px;
        }
    }
    .floorModal {
        position: absolute;
        left: 14px;
        top: 10px;
        font-weight: 700;
        font-size: 20px;
        color: #3d4d60;
    }
}   
   